import { jsx as _jsx } from "react/jsx-runtime";
import JoditEditor, { Jodit } from "jodit-react";
import { useMemo, useRef } from "react";
import { getUserToken } from "../../../../common/helpers/user-token";
const MailEditor = ({ content, setFieldValue }) => {
    const ref = useRef(null);
    const userToken = getUserToken();
    const config = useMemo(() => ({
        readonly: false,
        height: "calc(100vh - 166px)",
        controls: {
            font: {
                component: "select",
            },
            fontsize: {
                component: "select",
            },
        },
        buttons: [
            {
                tooltip: "Insert personalization field",
                template: () => `<button style="color: black; font-weight: semi-bold">Personalization field</button>`,
                text: "Personalization field",
                list: {
                    "Lead Company": "{lead_company}",
                    "E-mail": "{email}",
                    "First Name": "{first_name}",
                    "Last Name": "{last_name}",
                    "Job Title": "{job_title}",
                },
                childTemplate: (_, key, __) => `<span>${key}</span>`,
                exec: (editor, _, { control }) => {
                    const key = control.args[1];
                    editor.selection.insertHTML(key);
                },
            },
            "bold",
            "italic",
            "underline",
            "strikethrough",
            "eraser",
            "ul",
            "ol",
            "font",
            "fontsize",
            "paragraph",
            "lineHeight",
            "file",
            "image",
            "video",
            "spellcheck",
            "cut",
            "copy",
            "paste",
            "table",
            "link",
            "symbols",
            "indent",
            "outdent",
            "left",
            "brush",
            "undo",
            "redo",
            "preview",
        ],
        style: {
            padding: "32px",
        },
        className: "jodit-editor",
        processPasteFromWord: true,
        defaultActionOnPasteFromWord: "insert_clear_html",
        pasteHTMLActionList: Jodit.atom([
            { value: Jodit.constants.INSERT_AS_HTML, text: "Keep" },
            { value: Jodit.constants.INSERT_ONLY_TEXT, text: "Insert only Text" },
        ]),
        uploader: {
            url: `${process.env.REACT_APP_API_URL}/image/upload`,
            format: "json",
            headers: {
                Authorization: `Bearer ${userToken === null || userToken === void 0 ? void 0 : userToken.token}`,
            },
            isSuccess: function (res) {
                return !res.error;
            },
            process: function (res) {
                return res;
            },
            defaultHandlerSuccess: function (res) {
                const jodit = this;
                if (jodit) {
                    for (const file of res.files) {
                        jodit.s.insertImage(`${res.baseurl}/${file}`);
                    }
                }
            },
            defaultHandlerError: function () {
                console.log("error");
            },
        },
    }), [userToken === null || userToken === void 0 ? void 0 : userToken.token]);
    return (_jsx(JoditEditor, { ref: ref, value: content, config: config, 
        // NOTE: performance workaround
        onBlur: newContent => setFieldValue("content", newContent), onChange: _ => { } }));
};
export default MailEditor;
