import { gql } from '@apollo/client';
export var CampaignResponseStatuses;
(function (CampaignResponseStatuses) {
    CampaignResponseStatuses["Bounced"] = "BOUNCED";
    CampaignResponseStatuses["ColleagueNegative"] = "COLLEAGUE_NEGATIVE";
    CampaignResponseStatuses["ColleagueNeutral"] = "COLLEAGUE_NEUTRAL";
    CampaignResponseStatuses["ColleaguePositive"] = "COLLEAGUE_POSITIVE";
    CampaignResponseStatuses["Negative"] = "NEGATIVE";
    CampaignResponseStatuses["Neutral"] = "NEUTRAL";
    CampaignResponseStatuses["NotSent"] = "NOT_SENT";
    CampaignResponseStatuses["Other"] = "OTHER";
    CampaignResponseStatuses["Positive"] = "POSITIVE";
    CampaignResponseStatuses["Unsubscribed"] = "UNSUBSCRIBED";
})(CampaignResponseStatuses || (CampaignResponseStatuses = {}));
export var CampaignStatuses;
(function (CampaignStatuses) {
    CampaignStatuses["Active"] = "ACTIVE";
    CampaignStatuses["Completed"] = "COMPLETED";
    CampaignStatuses["Deleted"] = "DELETED";
    CampaignStatuses["Draft"] = "DRAFT";
    CampaignStatuses["Paused"] = "PAUSED";
})(CampaignStatuses || (CampaignStatuses = {}));
export var IntegrationProviders;
(function (IntegrationProviders) {
    IntegrationProviders["Google"] = "GOOGLE";
    IntegrationProviders["Microsoft"] = "MICROSOFT";
    IntegrationProviders["Ses"] = "SES";
})(IntegrationProviders || (IntegrationProviders = {}));
export const CampaignEmailFragmentDoc = gql `
    fragment CampaignEmail on CampaignEmailType {
  id
  isSent
  sendsAt
  subject
  body
}
    `;
export const CampaignDetailsFragmentDoc = gql `
    fragment CampaignDetails on CampaignDetailsType {
  id
  status
  createdAt
  name
  startsAt
  endsAt
  provider
  campaignEmails {
    ...CampaignEmail
  }
  contactList {
    id
    name
    contactsCount
  }
}
    ${CampaignEmailFragmentDoc}`;
export const CampaignEmailStatisticsFragmentDoc = gql `
    fragment CampaignEmailStatistics on CampaignEmailType {
  openRate
  totalSentCount
  uniqueOpenCount
  totalResponseCount
  totalRecipientsCount
  positiveResponseCount
  neutralResponseCount
  negativeResponseCount
  unsubscribeResponseCount
  bounceResponseCount
  otherResponseCount
}
    `;
export const CampaignStatisticsFragmentDoc = gql `
    fragment CampaignStatistics on CampaignDetailsType {
  openRate
  totalSentCount
  uniqueOpenCount
  totalResponseCount
  positiveResponseCount
  neutralResponseCount
  negativeResponseCount
  unsubscribeResponseCount
  bounceResponseCount
  otherResponseCount
}
    `;
export const TemplateFolderDataFragmentDoc = gql `
    fragment TemplateFolderData on TemplateFolderType {
  id
  name
  templatesCount
}
    `;
export const AddCampaignEmailDocument = gql `
    mutation addCampaignEmail($input: AddCampaignEmailInput!) {
  addCampaignEmail(input: $input) {
    __typename
    ... on AddCampaignEmailSuccessfulResponse {
      className
    }
    ... on CampaignNotExistResponse {
      className
    }
    ... on CampaignEmailInvalidDateResponse {
      className
    }
    ... on CampaignAlreadyActiveResponse {
      className
    }
  }
}
    `;
export const AddCampaignWithTemplateDocument = gql `
    mutation addCampaignWithTemplate($input: AddCampaignWithTemplateInput!) {
  addCampaignWithTemplate(input: $input) {
    __typename
    ... on CampaignWithTemplateSuccessfulAddedResponse {
      className
      id
      emailId
    }
    ... on TemplateNotExistResponse {
      className
    }
  }
}
    `;
export const AddCampaignDocument = gql `
    mutation addCampaign {
  addCampaign {
    __typename
    ... on AddCampaignSuccessfulResponse {
      className
      id
    }
  }
}
    `;
export const AddContactListDocument = gql `
    mutation addContactList($input: AddContactListInput!) {
  addContactList(input: $input) {
    __typename
    ... on ContactListSuccessfulAddedResponse {
      className
      id
    }
  }
}
    `;
export const AddContactDocument = gql `
    mutation addContact($input: AddContactInput!) {
  addContact(input: $input) {
    __typename
    ... on ContactSuccessfulAddedResponse {
      className
    }
    ... on ContactEmailTakenResponse {
      className
    }
    ... on ContactListNotExistResponse {
      className
    }
    ... on ContactUnsubscribedResponse {
      className
    }
  }
}
    `;
export const AddTemplateFolderDocument = gql `
    mutation addTemplateFolder($input: AddTemplateFolderInput!) {
  addTemplateFolder(input: $input) {
    __typename
    ... on AddTemplateFolderSuccessfulResponse {
      className
    }
  }
}
    `;
export const AddTemplateDocument = gql `
    mutation addTemplate($input: AddTemplateInput!) {
  addTemplate(input: $input) {
    __typename
    ... on AddTemplateSuccessfulResponse {
      className
    }
  }
}
    `;
export const CancelSubscriptionDocument = gql `
    mutation cancelSubscription {
  cancelSubscription {
    __typename
    ... on CancelSubscriptionSuccessfulResponse {
      className
    }
    ... on CancelSubscriptionAlreadyCancelledResponse {
      className
    }
  }
}
    `;
export const CopyCampaignDocument = gql `
    mutation copyCampaign($input: CopyCampaignInput!) {
  copyCampaign(input: $input) {
    __typename
    ... on CampaignSuccessfulCopiedResponse {
      className
    }
    ... on CampaignNotExistResponse {
      className
    }
  }
}
    `;
export const CopyFromListDocument = gql `
    mutation copyFromList($input: CopyFromListInput!) {
  copyFromList(input: $input) {
    __typename
    ... on CopyFromListSourceListNotFoundResponse {
      className
    }
    ... on CopyFromListSuccessfulDeletedResponse {
      className
    }
    ... on CopyFromListTargetListNotFoundResponse {
      className
    }
  }
}
    `;
export const DeleteCampaignEmailDocument = gql `
    mutation deleteCampaignEmail($input: DeleteCampaignEmailInput!) {
  deleteCampaignEmail(input: $input) {
    __typename
    ... on DeleteCampaignEmailSuccessfulResponse {
      className
    }
    ... on CampaignEmailNotExistResponse {
      className
    }
    ... on CampaignEmailAlreadySentResponse {
      className
    }
    ... on CampaignAlreadyActiveResponse {
      className
    }
  }
}
    `;
export const DeleteCampaignDocument = gql `
    mutation deleteCampaign($input: DeleteCampaignInput!) {
  deleteCampaign(input: $input) {
    __typename
    ... on DeleteCampaignSuccessfulResponse {
      className
    }
  }
}
    `;
export const DeleteContactListDocument = gql `
    mutation deleteContactList($input: DeleteContactListInput!) {
  deleteContactList(input: $input) {
    __typename
    ... on ContactListCampaignActiveResponse {
      className
    }
    ... on ContactListNotExistResponse {
      className
    }
    ... on ContactListSuccessfulDeletedResponse {
      className
    }
  }
}
    `;
export const DeleteContactDocument = gql `
    mutation deleteContact($input: DeleteContactInput!) {
  deleteContact(input: $input) {
    __typename
    ... on ContactSuccessfulDeleteResponse {
      className
    }
  }
}
    `;
export const DeleteTemplateFolderDocument = gql `
    mutation deleteTemplateFolder($input: DeleteTemplateFolderInput!) {
  deleteTemplateFolder(input: $input) {
    __typename
    ... on DeleteTemplateFolderSuccessfulResponse {
      className
    }
    ... on TemplateFolderNotExistResponse {
      className
    }
  }
}
    `;
export const DeleteTemplateDocument = gql `
    mutation deleteTemplate($input: DeleteTemplateInput!) {
  deleteTemplate(input: $input) {
    __typename
    ... on DeleteTemplateSuccessfulResponse {
      className
    }
    ... on TemplateNotExistResponse {
      className
    }
  }
}
    `;
export const DeleteUserDocument = gql `
    mutation deleteUser($input: DeleteUserInput!) {
  deleteUser(input: $input) {
    __typename
    ... on DeleteUserSuccessfulResponse {
      className
    }
    ... on UserNotExistResponse {
      className
    }
  }
}
    `;
export const DisableIntegrationDocument = gql `
    mutation disableIntegration($type: IntegrationProviders!) {
  disableIntegration(type: $type) {
    __typename
    ... on DisableIntegrationOkResponse {
      className
    }
    ... on DisableIntegrationNotFoundResponse {
      className
    }
  }
}
    `;
export const EditCampaignEmailDocument = gql `
    mutation editCampaignEmail($input: EditCampaignEmailInput!) {
  editCampaignEmail(input: $input) {
    __typename
    ... on EditCampaignEmailSuccessfulResponse {
      className
    }
    ... on CampaignEmailNotExistResponse {
      className
    }
    ... on CampaignAlreadyActiveResponse {
      className
    }
    ... on CampaignEmailAlreadySentResponse {
      className
    }
    ... on CampaignEmailInvalidDateResponse {
      className
    }
  }
}
    `;
export const EditCampaignResponseDocument = gql `
    mutation editCampaignResponse($input: EditCampaignResponseInput!) {
  editCampaignResponse(input: $input) {
    __typename
    ... on EditCampaignResponseSuccessfulResponse {
      className
    }
    ... on CampaignResponseNotExistResponse {
      className
    }
  }
}
    `;
export const EditCampaignDocument = gql `
    mutation editCampaign($input: EditCampaignInput!) {
  editCampaign(input: $input) {
    __typename
    ... on EditCampaignSuccessfulResponse {
      className
    }
    ... on CampaignNotExistResponse {
      className
    }
    ... on ContactListNotExistResponse {
      className
    }
    ... on DisableIntegrationNotFoundResponse {
      className
    }
    ... on CampaignAlreadyActiveResponse {
      className
    }
    ... on CampaignInvalidEndDateResponse {
      className
    }
    ... on CampaignDeletedResponse {
      className
    }
    ... on CampaignCompletedResponse {
      className
    }
  }
}
    `;
export const EditContactListDocument = gql `
    mutation editContactList($input: EditContactListInput!) {
  editContactList(input: $input) {
    __typename
    ... on ContactListNotExistResponse {
      className
    }
    ... on ContactListSuccessfulEditedResponse {
      className
    }
    ... on ContactListNameTakenResponse {
      className
    }
  }
}
    `;
export const EditContactDocument = gql `
    mutation editContact($input: EditContactInput!) {
  editContact(input: $input) {
    __typename
    ... on ContactSuccessfulEditedResponse {
      className
    }
    ... on ContactEmailTakenResponse {
      className
    }
    ... on ContactNotExistResponse {
      className
    }
  }
}
    `;
export const EditPasswordDocument = gql `
    mutation editPassword($input: EditPasswordInput!) {
  editPassword(input: $input) {
    __typename
    ... on EditPasswordSuccessfulResponse {
      className
    }
    ... on UserNotExistResponse {
      className
    }
    ... on InvalidPasswordResponse {
      className
    }
  }
}
    `;
export const EditTemplateFolderNameDocument = gql `
    mutation editTemplateFolderName($input: EditTemplateFolderNameInput!) {
  editTemplateFolderName(input: $input) {
    __typename
    ... on EditTemplateFolderNameSuccessfulResponse {
      className
    }
    ... on TemplateFolderNotExistResponse {
      className
    }
  }
}
    `;
export const EditTemplateDocument = gql `
    mutation editTemplate($input: EditTemplateInput!) {
  editTemplate(input: $input) {
    __typename
    ... on EditTemplateSuccessfulResponse {
      className
    }
    ... on TemplateNotExistResponse {
      className
    }
  }
}
    `;
export const EditUserDocument = gql `
    mutation editUser($input: EditUserInput!) {
  editUser(input: $input) {
    __typename
    ... on EditUserSuccessfulResponse {
      className
    }
    ... on UserNotExistResponse {
      className
    }
  }
}
    `;
export const EnableSesIntegrationDocument = gql `
    mutation enableSesIntegration($input: EnableSesIntegrationInput!) {
  enableSesIntegration(input: $input) {
    __typename
    ... on EnableSesIntegrationSuccessfulResponse {
      className
    }
    ... on EnableSesIntegrationInvalidResponse {
      className
    }
  }
}
    `;
export const HandleTemplateFavoriteDocument = gql `
    mutation handleTemplateFavorite($input: HandleTemplateFavoriteInput!) {
  handleTemplateFavorite(input: $input) {
    __typename
    ... on HandleTemplateFavoriteSuccessfulResponse {
      className
    }
    ... on TemplateNotExistResponse {
      className
    }
  }
}
    `;
export const ImportContactsDocument = gql `
    mutation importContacts($input: ImportContactsInput!) {
  importContacts(input: $input) {
    __typename
    ... on ImportContactsSuccessResponse {
      className
      results {
        firstName
        lastName
        email
        jobTitle
        leadCompany
        country
        status
        error
      }
      contactListName
    }
    ... on ContactListNotExistResponse {
      className
    }
    ... on ContactListNameTakenResponse {
      className
    }
  }
}
    `;
export const LoginDocument = gql `
    mutation login($input: LoginInput!) {
  login(input: $input) {
    __typename
    ... on TokenResponse {
      expiresAt
      token
    }
    ... on LoginInvalidResponse {
      className
    }
    ... on LoginNotVerifiedResponse {
      className
    }
  }
}
    `;
export const RefreshTokenDocument = gql `
    mutation refreshToken {
  refreshToken {
    __typename
    ... on TokenResponse {
      expiresAt
      token
    }
    ... on RefreshTokenInvalidResponse {
      className
    }
  }
}
    `;
export const ResetPasswordDocument = gql `
    mutation resetPassword($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    __typename
    ... on ResetPasswordResponse {
      className
    }
  }
}
    `;
export const SetPasswordDocument = gql `
    mutation setPassword($input: SetPasswordInput!) {
  setPassword(input: $input) {
    __typename
    ... on SetPasswordOkResponse {
      className
    }
    ... on SetPasswordInvalidResponse {
      className
    }
  }
}
    `;
export const SignupDocument = gql `
    mutation signup($input: SignupInput!) {
  signup(input: $input) {
    __typename
    ... on SignupEmailTakenResponse {
      className
    }
    ... on SignupOkResponse {
      className
    }
  }
}
    `;
export const StartCampaignDocument = gql `
    mutation startCampaign($input: StartCampaignInput!) {
  startCampaign(input: $input) {
    __typename
    ... on StartCampaignSuccessfulResponse {
      className
    }
    ... on CampaignNotExistResponse {
      className
    }
    ... on CampaignAlreadyActiveResponse {
      className
    }
    ... on CampaignEmailInvalidDateResponse {
      className
    }
    ... on CampaignContactListNotExists {
      className
    }
    ... on StartCampaignNoEmailsResponse {
      className
    }
    ... on StartCampaignNoProviderResponse {
      className
    }
    ... on StartCampaignEmailsInvalidDates {
      className
    }
  }
}
    `;
export const StopCampaignDocument = gql `
    mutation stopCampaign($input: StopCampaignInput!) {
  stopCampaign(input: $input) {
    __typename
    ... on StopCampaignSuccessfulResponse {
      className
    }
    ... on CampaignNotActiveResponse {
      className
    }
    ... on CampaignNotExistResponse {
      className
    }
  }
}
    `;
export const UnsubscribeDocument = gql `
    mutation unsubscribe($input: UnsubscribeInput!) {
  unsubscribe(input: $input) {
    __typename
    ... on UnsubscribeInvalidResponse {
      className
    }
    ... on UnsubscribeSuccessfulResponse {
      className
    }
  }
}
    `;
export const VerifySesIntegrationDocument = gql `
    mutation verifySesIntegration {
  verifySesIntegration {
    __typename
    ... on VerifySesIntegrationSuccessfulResponse {
      className
    }
    ... on VerifySesIntegrationInvalidResponse {
      className
    }
  }
}
    `;
export const VerifyUserDocument = gql `
    mutation verifyUser($input: VerifyUserInput!) {
  verifyUser(input: $input) {
    __typename
    ... on VerifyUserOkResponse {
      className
    }
    ... on VerifyUserInvalidTokenResponse {
      className
    }
  }
}
    `;
export const CurrentUserDocument = gql `
    query currentUser {
  currentUser {
    id
    email
    role
    firstName
    lastName
    hasDirectPayment
    stripeCustomerId
    checkoutCompletedAt
    subscription {
      id
      isTrial
      isActive
      isCanceled
      activationDate
      nextPaymentDate
    }
  }
}
    `;
export const GetAllContactListsDocument = gql `
    query getAllContactLists($searchPhrase: String) {
  getContactLists(searchPhrase: $searchPhrase) {
    items {
      id
      name
    }
  }
}
    `;
export const GetBillingDataDocument = gql `
    query getBillingData {
  getBillingData {
    billingData {
      id
      city
      country
      line1
      line2
      postal_code
      state
      email
      name
      taxId
    }
  }
}
    `;
export const GetCampaignDetailsDocument = gql `
    query getCampaignDetails($campaignId: String!) {
  getCampaignDetails(campaignId: $campaignId) {
    ...CampaignDetails
  }
}
    ${CampaignDetailsFragmentDoc}`;
export const GetCampaignEmailStatisticsDocument = gql `
    query getCampaignEmailStatistics($id: String!) {
  getCampaignEmail(id: $id) {
    ...CampaignEmailStatistics
  }
}
    ${CampaignEmailStatisticsFragmentDoc}`;
export const GetCampaignOverviewResponsesDocument = gql `
    query getCampaignOverviewResponses($searchPhrase: String, $pageNumber: Int, $pageSize: Int, $campaignId: String!) {
  getCampaignOverviewResponses(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
    campaignId: $campaignId
  ) {
    items {
      id
      firstName
      lastName
      email
      leadCompany
      jobTitle
      country
      status
      emails {
        sendsAt
        status
      }
    }
    emailCount
    pageCount
    pageNumber
    pageSize
    totalItems
  }
}
    `;
export const GetCampaignResponsesDocument = gql `
    query getCampaignResponses($searchPhrase: String, $campaignEmailId: String!, $pageNumber: Int, $pageSize: Int) {
  getCampaignResponses(
    campaignEmailId: $campaignEmailId
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
  ) {
    items {
      id
      openCount
      status
      country
      email
      firstName
      id
      jobTitle
      lastName
      leadCompany
    }
    pageCount
    pageNumber
    pageSize
    totalItems
  }
}
    `;
export const GetCampaignStatisticsDocument = gql `
    query getCampaignStatistics($campaignId: String!) {
  getCampaignDetails(campaignId: $campaignId) {
    ...CampaignStatistics
  }
}
    ${CampaignStatisticsFragmentDoc}`;
export const GetCampaignsDocument = gql `
    query getCampaigns($pageNumber: Int, $pageSize: Int, $searchPhrase: String, $companyOrEmail: String, $from: DateTime, $to: DateTime) {
  getCampaigns(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
    companyOrEmail: $companyOrEmail
    from: $from
    to: $to
  ) {
    items {
      id
      status
      createdAt
      name
      startsAt
      endsAt
      campaignEmails {
        id
      }
    }
    pageCount
    pageNumber
    pageSize
    totalItems
  }
}
    `;
export const GetContactListDocument = gql `
    query getContactList($contactListId: String!) {
  getContactList(contactListId: $contactListId) {
    createdAt
    id
    name
    isEdited
  }
}
    `;
export const GetContactListsDocument = gql `
    query getContactLists($pageNumber: Int, $pageSize: Int, $searchPhrase: String) {
  getContactLists(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
  ) {
    items {
      createdAt
      id
      name
      contactCount
    }
    pageCount
    pageNumber
    pageSize
    totalItems
  }
}
    `;
export const GetContactDocument = gql `
    query getContact($contactId: String!) {
  getContact(contactId: $contactId) {
    country
    createdAt
    email
    firstName
    id
    jobTitle
    lastName
    leadCompany
    isUnsubscribed
    hasActiveCampaign
    contactList {
      id
      name
    }
  }
}
    `;
export const GetContactsDocument = gql `
    query getContacts($pageSize: Int, $pageNumber: Int, $contactListId: String, $userId: String, $searchPhrase: String) {
  getContacts(
    pageSize: $pageSize
    pageNumber: $pageNumber
    contactListId: $contactListId
    userId: $userId
    searchPhrase: $searchPhrase
  ) {
    pageSize
    pageCount
    pageNumber
    totalItems
    items {
      country
      createdAt
      email
      firstName
      isUnsubscribed
      id
      jobTitle
      lastName
      leadCompany
      hasActiveCampaign
      contactList {
        id
        name
      }
    }
  }
}
    `;
export const GetInvoicesDocument = gql `
    query getInvoices {
  getInvoices {
    invoices {
      id
      number
      date
      billedAmount
      invoiceUrl
    }
  }
}
    `;
export const GetMessagesDocument = gql `
    query getMessages($pageNumber: Int, $pageSize: Int, $searchPhrase: String) {
  getLandingMessages(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
  ) {
    items {
      createdAt
      id
      name
      email
      message
    }
    pageCount
    pageNumber
    pageSize
    totalItems
  }
}
    `;
export const GetTemplateFolderDocument = gql `
    query getTemplateFolder($templateFolderId: String!) {
  getTemplateFolder(templateFolderId: $templateFolderId) {
    id
    name
  }
}
    `;
export const GetTemplateFoldersDocument = gql `
    query getTemplateFolders($pageNumber: Int, $pageSize: Int, $searchPhrase: String) {
  getTemplateFolders(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
  ) {
    items {
      ...TemplateFolderData
    }
  }
}
    ${TemplateFolderDataFragmentDoc}`;
export const GetTemplatesDocument = gql `
    query getTemplates($pageNumber: Int, $pageSize: Int, $searchPhrase: String, $templateFolderId: String, $isFavorite: Boolean) {
  getTemplates(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
    templateFolderId: $templateFolderId
    isFavorite: $isFavorite
  ) {
    items {
      createdAt
      id
      name
      subject
      body
      isFavorite
      templateFolder {
        id
        name
        createdAt
        templatesCount
      }
    }
    pageCount
    pageNumber
    pageSize
    totalItems
  }
}
    `;
export const GetUserDocument = gql `
    query getUser($userId: String!) {
  getUser(userId: $userId) {
    id
    email
    firstName
    lastName
    createdAt
    updatedAt
    role
    hasDirectPayment
  }
}
    `;
export const GetUsersDocument = gql `
    query getUsers($pageNumber: Int, $pageSize: Int, $searchPhrase: String) {
  getUsers(
    pageNumber: $pageNumber
    pageSize: $pageSize
    searchPhrase: $searchPhrase
  ) {
    pageSize
    pageCount
    pageNumber
    totalItems
    items {
      id
      email
      firstName
      lastName
      createdAt
      updatedAt
      role
      hasDirectPayment
    }
  }
}
    `;
export const HealthCheckDocument = gql `
    query healthCheck {
  healthCheck
}
    `;
export const GetIntegrationsStatusDocument = gql `
    query getIntegrationsStatus {
  getIntegrationsStatus {
    googleIntegration {
      isEnabled
      isValid
      email
    }
    microsoftIntegration {
      isEnabled
      isValid
      email
    }
    sesIntegration {
      isEnabled
      isValid
      email
    }
  }
}
    `;
